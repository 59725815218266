<template>
  <div class="apparea app-deal-home">
    <div>
      <div class="home-background m-auto" >
        <img :src="bannerHome?.large ?? ''" class="banner" alt="app-deal-home"/>
      </div>
      <button class="btn-close" @click="gotoDealFiter">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
    <div style="position:absolute;top:0px;height:0px"><h1 style="font-size: 0px">בוא נעוף - דילים מטורפים בכל יום</h1></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppDealHome',
  computed: {
    ...mapGetters({
      bannerHome: 'GET_APP_DEAL_BANNER',
    }),
  },
  created() {
    this.$store.dispatch('FETCH_APP_DEAL_BANNER');
  },
  methods: {
    gotoDealFiter() {
      this.$router.push({ path: '/deal-filter/app?channel=App' });
    },
  },
};
</script>

<style>
@import "/assets/css/bonaufApp.css";
</style>
